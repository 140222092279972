<template>
  <div class="line-chart svg-container" ref="lineChart">
    <div class="mb-3 mt-2 chart-title">
      <span
        class="fw-bold d-block d-sm-inline-block"
        :class="type === 'daily' ? 'fs-5' : 'fs-6'"
        >{{ title }}</span
      >
      <template v-if="type === 'daily'">
        <span
          class="pe-2 ps-sm-3 fw-bold d-inline-block"
          v-for="(item, key) in chartColor"
          :key="key"
        >
          <span
            class="d-inline-block"
            :style="{ color: item }"
            :class="key === 'notify' ? 'fs-4' : ''"
          >
            <svg
              v-if="key === 'notify'"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <polygon points="0,0 7,14 14,0" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="7" cy="7" r="7" />
            </svg>
          </span>

          <span class="d-inline-block">{{ $t('__' + key) }}</span>
          <span v-if="key === 'notify' && title === $t('__rrValue')">【{{ $t('__RR') }}】</span>
          <span v-else-if="key === 'notify' && title === $t('__restlessnessValue')">【{{ $t('__restless') }}】</span>
          <span v-else-if="key === 'notify' && title === $t('__heartRateValue')">【{{ $t('__heartRate') }}】</span>
          <span v-else-if="key === 'notify' && title === $t('__spo2Value')">【{{ $t('__spo2') }}】</span>
          <span v-else-if="key === 'notify' && title === $t('__tempValue')">【{{ $t('__temp') }}】</span>
        </span>
      </template>
      <span
        v-if="type === 'daily'"
        class="pe-2 ps-sm-3 fw-bold d-inline-block"
      >
        <span class="d-inline-block" style="color: rgba(253, 97, 97, 0.35)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="7" cy="7" r="7" />
          </svg>
        </span>
        <span class="d-inline-block">{{ $t('__notifyRange') }}</span>
      </span>
    </div>
    <div class="d-flex align-items-center flex-md-row flex-column">
      <div
        v-if="type !== 'daily' && statistics"
        :style="windowWidth > 576 ? 'width: 120px' : 'width: 100%'"
        :class="windowWidth > 576 ? '' : 'd-flex align-items-end mb-3'"
      >
        <template v-if="lineColor === '#6966D8'">
          {{ $t('__sleepBreathingRate') + ' : ' }}
          <div :class="windowWidth > 576 ? '' : 'ms-2'">
            <span class="fs-3 fw-bold" :style="'color:' + lineColor">{{
              Math.round(Number(statistics.rr_rate || 0) * 10) / 10
            }}</span>
            {{ $t('__times_min') }}
          </div>
        </template>
        <template v-else-if="lineColor === '#FF6347'">
          {{ $t('__heartRateValue') + ' : ' }}
          <div :class="windowWidth > 576 ? '' : 'ms-2'">
            <span class="fs-3 fw-bold" :style="'color:' + lineColor">{{
                Math.round(Number(statistics.heartRate || 0) * 10) / 10
              }}</span>
            {{ $t('__times_min') }}
          </div>
        </template>
        <template v-else-if="lineColor === '#1E90FF'">
          {{ $t('__spo2Value') + ' : ' }}
          <div :class="windowWidth > 576 ? '' : 'ms-2'">
            <span class="fs-3 fw-bold" :style="'color:' + lineColor">{{
                Math.round(Number(statistics.spo2 || 0) * 10) / 10
              }}</span>
            {{ $t('__percent_min') }}
          </div>
        </template>
        <template v-else-if="lineColor === '#FFD700'">
          {{ $t('__tempValue') + ' : ' }}
          <div :class="windowWidth > 576 ? '' : 'ms-2'">
            <span class="fs-3 fw-bold" :style="'color:' + lineColor">{{
                Math.round(Number(statistics.temp || 0) * 10) / 10
              }}</span>
            {{ $t('__degree_min') }}
          </div>
        </template>
      </div>
      <div
        class="svg"
        :style="
          type !== 'daily' && statistics && windowWidth > 576
            ? 'width: calc(100% - 120px)'
            : ''
        "
      >
        <svg
          :width="svgWidth + svgPaddingX"
          :height="svgHeight + svgPaddingY"
          v-if="redrawToggle"
          streamable="true"
          version="1.2"
        >
          <template v-if="lineColor !== '#8B4513' && lineColor !== '#4682B4' && lineColor !== '#6A5ACD'">
            <g
              x="0"
              y="0"
              :transform="`translate(${svgPaddingX - 20},${svgPaddingY / 2})`"
            >
              <path
                ref="RRlineDash"
                stroke="#c4c4c4"
                stroke-width="1"
                stroke-dasharray="4"
                fill="none"
              />
            </g>
            <g
              x="0"
              y="0"
              :transform="`translate(${svgPaddingX - 20},${svgPaddingY / 2})`"
            >
              <path
                ref="RRline"
                :stroke="lineColor"
                stroke-width="1.5"
                fill="none"
              />
            </g>
          </template>
          <g
            x="0"
            y="0"
            ref="xAxis"
            :transform="
              `translate(${svgPaddingX - 20},${svgPaddingY / 2 + svgHeight})`
            "
          ></g>
          <g
            x="0"
            y="0"
            ref="yAxis"
            :transform="`translate(${svgPaddingX - 20},${svgPaddingY / 2})`"
          ></g>
          <g
            class="no-print"
            v-if="sliderDate !== null"
            x="0"
            y="0"
            :transform="`translate(${svgPaddingX - 20},0)`"
            style="pointer-events: none;"
          >
            <rect
              :y="0"
              width="6"
              :height="svgHeight + svgPaddingY"
              fill="rgba(0, 0, 0, .3)"
              ref="mousemoveRect"
              transform="translate(-3,0)"
            />
          </g>
          <template v-if="lineColor !== '#8B4513' && lineColor !== '#4682B4' && lineColor !== '#6A5ACD'">
            <g
              v-if="notifyMax && lineColor !== '#1E90FF'"
              x="0"
              y="0"
              :transform="`translate(${svgPaddingX - 20},${svgPaddingY / 2})`"
            >
              <rect
                :y="0"
                :width="svgWidth"
                :height="yScale(notifyMax)"
                fill="rgba(253, 97, 97, 0.1)"
              />
            </g>
            <g
                v-if="lineColor === '#1E90FF'"
                x="0"
                y="0"
                :transform="`translate(${svgPaddingX - 20},${svgPaddingY / 2})`"
            >
              <rect
                :y="yScale(notifyMax)"
                :width="svgWidth"
                :height="yScale(60) - yScale(notifyMax)"
                fill="rgba(253, 97, 97, 0.1)"
              />
            </g>
            <g
              v-if="notifyMin && lineColor !== '#1E90FF'"
              x="0"
              y="0"
              :transform="`translate(${svgPaddingX - 20},${svgPaddingY / 2})`"
            >
              <rect
                :y="yScale(notifyMin)"
                :width="svgWidth"
                :height="svgHeight - yScale(notifyMin)"
                fill="rgba(253, 97, 97, 0.1)"
              />
            </g>
            <g
              v-if="notify"
              x="0"
              y="0"
              :transform="`translate(${svgPaddingX - 20},0)`"
            >
              <polygon
                ref="notifyPolygon"
                v-for="(item, index) in notify"
                :key="'notify' + item[xKey] + index"
                :y="0"
                points="0,0 8,16 16,0"
                :fill="chartColor.notify"
                :transform="`translate(-${polygonWidth / 2},0)`"
              />
            </g>
          </template>
          <g
            x="0"
            y="0"
            :transform="`translate(${svgPaddingX - 20},${svgPaddingY / 2})`"
            ref="lineSVG"
          >
            <rect
              :y="0"
              :width="svgWidth"
              :height="svgHeight"
              fill="rgba(0, 0, 0, 0)"
            />
          </g>
        </svg>
        <div id="tooltip" class="no-print">
          <div class="tooltip shadow rounded" ref="tooltip"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { select, selectAll, pointer } from 'd3-selection'
import { scaleTime, scaleLinear } from 'd3-scale'
import { timeHour } from 'd3-time'
import { bisector } from 'd3-array'
import { line } from 'd3-shape'
import { axisBottom, axisLeft } from 'd3-axis'
import { drag } from 'd3-drag'
import 'd3-transition'
import { mapMutations, mapState, mapGetters } from 'vuex'
import i18n from '@/lang/lang.js'

export default {
  name: 'LineChart',
  props: [
    'type',
    'startAt',
    'endAt',
    'xKey',
    'yKey',
    'yKey2',
    'svgPaddingX',
    'svgPaddingY',
    'data',
    'notify',
    'chartColor',
    'lineColor',
    'repaint',
    'notifyMax',
    'notifyMin',
    'title',
    'statistics'
  ],
  data () {
    return {
      svgWidth: 0,
      svgHeight: 100,
      redrawToggle: true,
      polygonWidth: 16,
      touchX: 0,
      mousemoveBedStatus: null,
      windowWidth: window.innerWidth,
      sliderDate: 0,
      colorNameMap: {
        '#6966D8': '__rrValue',
        '#07CD32': '__restlessnessValue',
        '#FF6347': '__heartRateValue',
        '#1E90FF': '__spo2Value',
        '#FFD700': '__tempValue',
        '#8B4513': '__foraPressureValue',
        '#4682B4': '__foraOxygenValue',
        '#6A5ACD': '__foraTemperatureValue'
      }
    }
  },
  computed: {
    ...mapState(['timezone']),
    ...mapGetters(['notify_type_name']),
    chartData () {
      const copyData = JSON.parse(JSON.stringify(this.data))
      var date = copyData.map(d => {
        d.time = Number(d.time) * 1000
        d.value = Number(d.value) < 0 ? null : Number(d.value)
        d.value2 = Number(d.value2) < 0 ? null : Number(d.value2)

        return JSON.parse(JSON.stringify(d))
      })
      date.sort((a, b) => {
        return Number(a.time) - Number(b.time)
      })
      return date
    },
    filterNoData () {
      const copyData = JSON.parse(JSON.stringify(this.data))
      const filterData = copyData.filter(data => Number(data.value) >= 0)
      var date = filterData.map(d => {
        d.time = Number(d.time) * 1000
        d.value = Number(d.value) < 0 ? null : Number(d.value)
        return JSON.parse(JSON.stringify(d))
      })
      date.sort((a, b) => {
        return Number(a.time) - Number(b.time)
      })
      return date
    },
    maxValue () {
      switch (this.lineColor) {
        case '#6966D8': // respiration 的顏色
          return 40
        case '#07CD32': // restless 的顏色
          return 100
        case '#FF6347': // HeartRate 的顏色
          return 160
        case '#1E90FF': // SpO2 的顏色
          return 110
        case '#FFD700': // Temp 的顏色
          return 50
        case '#8B4513': // Fora Pressure 的顏色
          return 200
        case '#4682B4': // Fora Oxygen 的顏色
          return 110
        case '#6A5ACD': // Fora Temperature 的顏色
          return 50

        default:
          return 100
      }
    },
    minValue () {
      if (!this.chartData || !this.chartData.length) return 0
      var arrayValue = []
      this.chartData.forEach(data => {
        arrayValue.push(Number(data[this.yKey]))
      })
      return Math.min(...arrayValue)
    },
    xScale () {
      return scaleTime()
        .range([0, this.svgWidth])
        .domain([this.startAt, this.endAt])
    },
    yScale () {
      switch (this.lineColor) {
        case '#1E90FF':
        case '#8B4513':
          return scaleLinear()
            .range([this.svgHeight, 0])
            .domain([60, this.maxValue])

        case '#FF6347':
          return scaleLinear()
            .range([this.svgHeight, 0])
            .domain([40, this.maxValue])

        default:
          return scaleLinear()
            .range([0, this.svgHeight])
            .domain([this.maxValue, 0])
      }
    },
    xAxis () {
      const vm = this
      return axisBottom(vm.xScale)
        .ticks(timeHour.every(1))
        .tickFormat(function (d) {
          return vm.$getTimeZoneDate(
            new Date(d).getTime() / 1000,
            vm.timezone,
            'HH'
          )
        })
    },
    yAxis () {
      return axisLeft(this.yScale).ticks(5)
    },
    statusDataScale () {
      return scaleTime()
        .range([0, this.svgWidth])
        .domain([this.startAt, this.endAt])
    },
    firstLine () {
      return line()
        .x(d => this.xScale(new Date(d[this.xKey])))
        .y(0)
    },
    line () {
      return line()
        .x(d => this.xScale(new Date(d[this.xKey])))
        .y(d => this.yScale(Number(d[this.yKey]) < 0 ? '0' : d[this.yKey]))
        .defined(d => {
          if (d[this.yKey] === null) return false
          const value = Number(d[this.yKey])

          switch (this.lineColor) {
            case '#1E90FF': // SpO2
              return value >= 60 && value <= this.maxValue
            case '#FF6347': // HeartRate
              return value >= 40 && value <= this.maxValue
            default:
              return value >= 0 && value <= this.maxValue
          }
        })
    }
  },
  watch: {
    sliderDate () {
      this.mousemoveStarted()
    },
    repaint (data) {
      if (data) {
        this.repaintChart()
      }
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded']),
    mouseover (event) {
      select(this.$refs.tooltip).style('opacity', 1)
    },
    mousemoveNotify (event, data) {
      const vm = this
      select(vm.$refs.tooltip)
        .html(
          vm.notify_type_name[data.value] +
            '(' +
            this.$getTimeZoneDate(
              new Date(data.time * 1000),
              this.timezone,
              'YYYY-MM-DD HH:mm'
            ) +
            ')'
        )
        .style(
          'left',
          (vm.svgWidth / 2 < pointer(event)[0]
            ? pointer(event)[0] - 210
            : pointer(event)[0] + vm.svgPaddingX) + 'px'
        )
        .style('bottom', pointer(event)[1] + 'px')
        .style('top', 'auto')
    },
    mousemoveLineChart (event) {
      const vm = this
      const data = vm.chartData
      const x = pointer(event)[0]
      const y = pointer(event)[1] - vm.svgPaddingY
      const targetTime = new Date(vm.xScale.invert(x)).getTime()
      const bisect = bisector(d => d.time).right
      const i = bisect(data, targetTime)
      if (!data[i]) return
      vm.sliderDate = data[i].time
      select(vm.$refs.tooltip)
        .html(() => {
          if (data[i].value === null) {
            return ''
          }

          let htmlContent = `
            ${i18n.t(vm.colorNameMap[vm.lineColor] || '')}
            ${data[i].value} ( ${this.$getTimeZoneDate(
                  new Date(data[i].time),
                  this.timezone,
                  'YYYY-MM-DD HH:mm'
                )} )
          `
          if (this.lineColor === '#8B4513') {
            htmlContent = `
              ${i18n.t(vm.colorNameMap[vm.lineColor] || '')}
              ${data[i].value} ~ ${data[i].value2} ( ${this.$getTimeZoneDate(
              new Date(data[i].time),
              this.timezone,
              'YYYY-MM-DD HH:mm'
            )} )`
          }

          return htmlContent
        })
        .style('opacity', data[i].value === null ? 0 : 1)
        .style(
          'left',
          (vm.svgWidth / 2 < pointer(event)[0]
            ? pointer(event)[0] - 210
            : pointer(event)[0] + vm.svgPaddingX) + 'px'
        )
        .style('top', y + 'px')
        .style('bottom', 'auto')
    },
    mouseleave () {
      const vm = this
      vm.sliderDate = 0
      select(this.$refs.tooltip).style('opacity', 0)
    },
    animateLoad () {
      select(this.$refs.RRlineDash).attr('d', this.line(this.filterNoData))
      select(this.$refs.RRline).attr('d', this.line(this.chartData))

      if (
        this.lineColor === '#4682B4' ||
        this.lineColor === '#6A5ACD'
      ) {
        const svg = select(this.$refs.lineSVG)

        const circles = svg.selectAll('circle')
          .data(this.chartData)
          .enter()
          .append('circle')

        circles
          .attr('cx', d => this.xScale(new Date(d[this.xKey])))
          .attr('cy', d => this.yScale(Number(d[this.yKey]) < 0 ? '0' : d[this.yKey]))
          .attr('r', 5)
          .attr('fill', this.lineColor)
      }

      if (this.lineColor === '#8B4513') {
        const bars = select(this.$refs.lineSVG)
          .selectAll('.bar')
          .data(this.chartData)
          .enter()
          .append('rect')
          .attr('class', 'bar')

        bars
          .attr('x', d => {
            return this.xScale(new Date(d[this.xKey]))
          })
          .attr('y', d => this.svgHeight - this.yScale(Number(d[this.yKey2]) < 0 ? '0' : d[this.yKey2]))
          .attr('width', 5)
          .attr('height', d => Math.abs(this.yScale(Number(d[this.yKey]) < 0 ? '0' : d[this.yKey]) - this.yScale(Number(d[this.yKey2]) < 0 ? '0' : d[this.yKey2])))
          .attr('fill', 'steelblue')
      }

      select(this.$refs.lineSVG)
        .on('mouseover', this.mouseover)
        .on('mousemove', this.mousemoveLineChart)
        .on('mouseleave', this.mouseleave)

      select(this.$refs.xAxis).call(this.xAxis)
      select(this.$refs.yAxis).call(this.yAxis)

      if (!this.notify) return
      selectAll(this.$refs.notifyPolygon)
        .data(this.notify)
        .attr('points', d => {
          return (
            this.xScale(new Date(d[this.xKey] * 1000)) +
            ',0 ' +
            (this.xScale(new Date(d[this.xKey] * 1000)) +
              this.polygonWidth / 2) +
            ',' +
            this.polygonWidth +
            ' ' +
            (this.xScale(new Date(d[this.xKey] * 1000)) + this.polygonWidth) +
            ',0'
          )
        })
        .on('mouseover', this.mouseover)
        .on('mousemove', this.mousemoveNotify)
        .on('mouseleave', this.mouseleave)
    },
    repaintChart () {
      this.$nextTick(function () {
        const vm = this
        let value

        if (vm.type === 'daily') {
          value = 0
        } else {
          value = 120
        }

        if (vm.type !== 'daily' && vm.lineColor === '#6966D8') {
          value = 120
        }

        const width = Number(
          vm.$refs.lineChart.offsetWidth - vm.svgPaddingX - value
        )

        if (
          width <= 1402 &&
          document.querySelector('body').className.indexOf('no-print') === -1
        ) {
          vm.svgWidth = vm.type !== 'daily' && vm.statistics ? 1260 : 1401
        } else {
          vm.svgWidth = width
        }

        vm.animateLoad()
      })
    },
    addResizeListener () {
      window.addEventListener('resize', () => {
        const vm = this
        if (vm.windowWidth === window.innerWidth) return
        vm.redrawToggle = false
        setTimeout(() => {
          vm.redrawToggle = true
          vm.$nextTick(function () {
            vm.repaintChart()
          })
        }, 0)
      })
    },
    mousemoveStarted () {
      const vm = this
      select(vm.$refs.mousemoveRect)
        .transition()
        .duration(100)
        .attr('x', () => {
          return this.xScale(new Date(this.sliderDate))
        })
    },
    dragStarted () {
      const vm = this
      select(vm.$refs.mousemoveRect).call(
        drag().on('drag', function (event) {
          event.x >= vm.svgWidth
            ? (vm.touchX = vm.svgWidth)
            : event.x <= 0
              ? (vm.touchX = 0)
              : (vm.touchX = event.x)
        })
      )
    }
  },
  created () {},
  mounted () {
    this.$nextTick(function () {
      this.repaintChart()
      this.addResizeListener()
    })
  }
}
</script>

<style lang="scss" scoped>
#tooltip {
  .tooltip {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    color: #fff;
    background-color: rgba(#000, 0.7);
    padding: 5px;
    min-width: 210px;
  }
}
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 1%;
  vertical-align: top;
  overflow: hidden;
  .svg {
    position: relative;
    width: 100%;
    overflow-x: auto;
    &::v-deep text {
      font-size: 14px;
    }
  }
}
</style>
